import React from 'react'
import Meta from '../../../components/Meta'
import { GeneralApplicationForm } from '../../../components/GeneralApplicationForm'
import { useGeneralApplication } from '../../../hooks/useGeneralApplication'

const GeneralApplication = () => {
  const { generalApplication } = useGeneralApplication()
  const {title, copy} = generalApplication
  return (
    <>
      <Meta pageTitle={'Career Positions'} />
      <GeneralApplicationForm title={title} copy={copy}/>
    </>
  )
}

export default GeneralApplication
