import { graphql, useStaticQuery } from 'gatsby'

export const useGeneralApplication = () => {
  const data = useStaticQuery(graphql`
    query {
      generalApplication: sanityGeneralApplication {
        title
        copy
      }
    }
  `)

  const { generalApplication } = data || {}

  return { generalApplication }
}
