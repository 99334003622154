import React, { useState } from 'react'
import { Link } from 'gatsby'
import { useForms } from '../hooks/useForms'
import { ArrowLeftShort, SignUpButton } from './Icons'

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export const GeneralApplicationForm = ({ title, copy }) => {
  const { career, teams } = useForms()
  const [value, setValue] = useState({})
  const [success, setSuccess] = useState(false)
  const charCount = str => str.replace(/\s/g, '').length

  const limit = 300
  const count = charCount(value.message || '')
  const exceeded = count > limit

  const MAX_TOTAL_SIZE = 8 * 1024 * 1024
  const [totalFileSize, setTotalFileSize] = useState(0)
  const [error, setError] = useState('')
  const [fileSizes, setFileSizes] = useState({
    resume: null,
    coverLetter: null,
  })

  const handleChange = e => {
    const { name, value, files } = e.target
    if (files && files.length > 0) {
      const file = files[0]
      const fileSize = file.size

      const newTotalSize = totalFileSize - (value[name]?.size || 0) + fileSize

      if (newTotalSize > MAX_TOTAL_SIZE) {
        setError('The combined file size must not exceed 8MB.')
        return
      }

      setError('')
      setValue(prevValue => ({ ...prevValue, [name]: file }))
      setTotalFileSize(newTotalSize)
      setFileSizes(prevSizes => ({
        ...prevSizes,
        [name]: `${(fileSize / 1024).toFixed(2)} KB`,
      }))
    } else {
      setValue(prevValue => ({ ...prevValue, [name]: value }))
    }
  }

  const handleCheckboxChange = e => {
    const { value, checked } = e.target
    setValue(prevValue => {
      const updatedTeamCategories = checked
        ? [...(prevValue.teams || []), value]
        : (prevValue.teams || []).filter(item => item !== value)

      return { ...prevValue, teams: updatedTeamCategories }
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target

    if (error) {
      alert('Please resolve the errors before submitting the form.')
      return
    }

    fetch('/', {
      method: 'POST',
      body: new FormData(form),
    })
      .then(() => {
        form.reset()
        setSuccess(true)
      })
      .catch(error => alert(error))
  }

  return (
    <section className="pt-33 md:header-padding relative">
      <div className="px-4 pt-9 md:px-[5%] md:pt-[2%] xl:max-w-[85%]">
        <Link to="/careers/positions" className="w-fit block md:mt-[2%]">
          <span className="flex align-center w-fit">
            <ArrowLeftShort className="w-10 h-4 mt-1 md:w-12 md:h-5" />
            Back to jobs
          </span>
        </Link>
        <h3 className="h4 mt-12">{title}</h3>
        <p className="mt-8 body-sm">{copy}</p>
        {!success ? (
          <>
            <form
              name="generalApplication"
              method="POST"
              action="/"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={handleSubmit}
              className="body flex flex-col"
              encType="multipart/form-data"
            >
              <input
                type="hidden"
                name="form-name"
                value="generalApplication"
              />
              <input type="hidden" name="role" value="General Application" />
              <div className="flex flex-col flex-1 relative justify-evenly pt-[5%]">
                <div className="flex flex-col">
                  <div className="flex gap-1 max-w-[680px]">
                    <input
                      type="text"
                      name="firstName"
                      autoComplete="off"
                      onChange={handleChange}
                      className="px-2 py-1 md:py-2 mb-5 bg-transparent border-2 focus-field w-full max-w-[650px] body-sm"
                      placeholder="First Name"
                      required
                    />
                    <span className="text-red h-fit body leading-none  w-[8px]">
                      *
                    </span>
                  </div>
                  <div className="flex gap-1 max-w-[680px]">
                    <input
                      type="text"
                      name="lastName"
                      autoComplete="off"
                      onChange={handleChange}
                      className="px-2 py-1 md:py-2 mb-5 bg-transparent border-2 focus-field w-full max-w-[650px] body-sm"
                      placeholder="Last Name"
                      required
                    />
                    <span className="text-red h-fit body leading-none  w-[8px]">
                      *
                    </span>
                  </div>
                  <div className="flex gap-1 max-w-[680px]">
                    <input
                      type="tel"
                      name="phoneNumber"
                      autoComplete="off"
                      onChange={handleChange}
                      className="px-2 py-1 md:py-2 mb-5 bg-transparent border-2 focus-field w-full max-w-[650px] body-sm"
                      placeholder="Phone Number"
                      required
                    />
                    <span className="text-red h-fit body leading-none  w-[8px]">
                      *
                    </span>
                  </div>
                  <div className="flex gap-1 max-w-[680px]">
                    <input
                      type="email"
                      name="email"
                      autoComplete="off"
                      onChange={handleChange}
                      className="px-2 py-1 md:py-2 mb-5 bg-transparent border-2 focus-field w-full max-w-[650px] body-sm"
                      placeholder="Email"
                      required
                    />
                    <span className="text-red h-fit body leading-none  w-[8px]">
                      *
                    </span>
                  </div>

                  <div className="flex flex-col max-w-[650px] mt-5 mb-7">
                    {error && <p className="text-red text-sm mt-2">{error}</p>}
                    <label className="font-semibold mb-2">
                      Resume
                      <span className="text-red h-fit body leading-none ml-1 font-normal">
                        *
                      </span>
                      <span className="font-normal text-sm">
                        {' '}
                        pdf preferred
                      </span>
                    </label>
                    <input
                      type="file"
                      name="resume"
                      accept=".pdf,.doc,.docx"
                      onChange={handleChange}
                      className="body-sm"
                      required
                    />
                    {fileSizes.resume && (
                      <p className="text-sm text-gray-500 mt-2">
                        File size: {fileSizes.resume}
                      </p>
                    )}
                  </div>
                  <div className="flex flex-col max-w-[650px] mb-10">
                    <label className="font-semibold mb-2">
                      Cover Letter
                      <span className="font-normal text-sm">
                        {' '}
                        pdf preferred
                      </span>
                    </label>
                    <input
                      type="file"
                      name="coverLetter"
                      accept=".pdf,.doc,.docx"
                      onChange={handleChange}
                      className="body-sm"
                    />
                    {fileSizes.coverLetter && (
                      <p className="text-sm text-gray-500 mt-2">
                        File size: {fileSizes.coverLetter}
                      </p>
                    )}
                  </div>
                  <div className="mb-10">
                    <p className="font-semibold mb-2">
                      Which team are you interest in?
                      <span className="text-red h-fit body leading-none ml-1 font-normal">
                        *
                      </span>
                    </p>
                    {teams?.map((item, index) => (
                      <label
                        key={index}
                        className="flex items-center space-x-2 mb-2"
                      >
                        <input
                          type="checkbox"
                          name="teams"
                          value={item}
                          onChange={handleCheckboxChange}
                          className="w-4 h-4 body-sm"
                        />
                        <span className="body-sm">{item}</span>
                      </label>
                    ))}
                    {Array.isArray(value.teams) &&
                      value.teams.includes('Other') && (
                        <div className="flex gap-1 max-w-[680px]">
                          <input
                            type="text"
                            name="teamOtherCategory"
                            placeholder="Please specify"
                            onChange={handleChange}
                            className="px-2 py-1 md:py-2 mb-5 bg-transparent border-2 focus-field w-full max-w-[650px]"
                            required
                          />
                          <span className="text-red h-fit body leading-none">
                            *
                          </span>
                        </div>
                      )}
                    <input
                      type="hidden"
                      name="teamOther"
                      value={value.teamOtherCategory || ''}
                    />
                  </div>
                  <input
                    type="text"
                    name="Website"
                    autoComplete="off"
                    onChange={handleChange}
                    className="body-sm px-2 py-1 md:py-2 mb-5 bg-transparent border-2 focus-field w-full max-w-[650px]"
                    placeholder="Website"
                  />
                  <input
                    type="text"
                    name="linkedIn"
                    autoComplete="off"
                    onChange={handleChange}
                    className="body-sm px-2 py-1 md:py-2 mb-5 bg-transparent border-2 focus-field w-full max-w-[650px]"
                    placeholder="LinkedIn Profile"
                  />
                  <select
                    name="category"
                    onBlur={handleChange}
                    className="body-sm px-1 py-1 md:py-2 mb-5 bg-transparent border-2 focus-field w-full max-w-[650px]"
                  >
                    <option value="" defaultValue>
                      How did you hear about this opportunity?
                    </option>
                    {career?.map((item, index) => (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    ))}
                  </select>
                  {value.category === 'Other (please specify)' && (
                    <div className="flex gap-1 max-w-[680px]">
                      <input
                        type="text"
                        name="otherCategory"
                        placeholder="Please specify"
                        onChange={handleChange}
                        className="body-sm px-2 py-1 md:py-2 mb-5 bg-transparent border-2 focus-field w-full max-w-[650px]"
                        required
                      />
                      <span className="text-red h-fit body leading-none  w-[8px]">
                        *
                      </span>
                    </div>
                  )}
                  <input
                    type="hidden"
                    name="other"
                    value={value.otherCategory || ''}
                  />
                </div>
                <div className="flex flex-col justify-center max-w-[650px]">
                  <span className="self-end">
                    {count}/{limit}
                  </span>
                  <textarea
                    name="message"
                    onChange={handleChange}
                    className="body-sm p-2 bg-transparent border-2 focus-field"
                    rows={5}
                    placeholder="Anything else you'd like to share?"
                    required
                  />
                  {exceeded && (
                    <span className="mt-2 body text-xs">
                      Max 300 characters allowed
                    </span>
                  )}
                </div>
                <div className="mt-4 max-w-[650px]">
                  <label className="block mb-2 font-semibold">
                    Are you interested in joining our mailing list to receive
                    notifications about updates and progress at New Culture?{' '}
                  </label>
                  <div className="flex space-x-4">
                    <label className="body-sm flex items-center">
                      <input
                        type="radio"
                        name="subscribe"
                        value="yes"
                        onChange={handleChange}
                        className="mr-2"
                      />
                      Yes
                    </label>
                    <label className="body-sm flex items-center">
                      <input
                        type="radio"
                        name="subscribe"
                        value="no"
                        onChange={handleChange}
                        className="mr-2"
                      />
                      No
                    </label>
                  </div>
                </div>
                <div className="mt-12 max-w-[650px] w-full flex items-center">
                  <button
                    className="relative flex flex-col items-center self-center mx-auto"
                    type="submit"
                  >
                    <SignUpButton className="absolute w-[180%] top-0 bottom-0 h-auto" />
                    <div className="h4-alt p-2 md:p-3">Submit</div>
                  </button>
                </div>
              </div>
            </form>
          </>
        ) : (
          <div className="h-full pt-24 md:pt-32">
            <div className="relative flex w-full h-full justify-center items-center max-w-[650px]">
              <p className="body">
                Thanks for submitting your application to our candidate pool!
                We're excited to learn more about you and will be in touch if
                there is an opening that fits your profile.
              </p>
            </div>
          </div>
        )}
      </div>
    </section>
  )
}
